import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TrashIcon, PlusIcon, PauseIcon, PlayIcon, CheckIcon, XMarkIcon  } from '@heroicons/react/24/outline';

import { InfinitySpin } from 'react-loader-spinner';

const TestAllEndpoints = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Define the fetch function
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post('https://node.aryzap.com/api/testAllEndpoints');
      setData(response.data);
    } catch (error) {
      console.error('Error fetching endpoints:', error);
    } finally {
      setIsLoading(false);
    }
  };

  

  return (
    <div className="p-4">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-bold">API UNIT TESTING</h1>
        <button
          className="bg-red-600 hover:bg-red-500 text-white px-4 py-2 rounded-md flex items-center"
          onClick={fetchData}
        >
          <PlayIcon className="w-5 h-5 mr-2" />
          
          Start
        </button>
      </div>

      {isLoading ? (
        <div className="flex flex-col items-center justify-center w-full h-screen bg-gray-900">
            <InfinitySpin width="200" color="#4A90E2" />
            <p className="text-2xl text-white mt-4">Testing… don&apos;t close!</p>
        </div>
        ) : data.results ? (
        <>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-2 mb-10">
            
                <div className="rounded-md bg-gray-950 shadow-md">
                    <div className="p-6 ">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">

                                <h2 className="text-sm font-bold mt-2">
                                    Total APIS
                                </h2>
                                <p >
                                    {data.totalAPIs}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-md bg-gray-950 shadow-md">
                    <div className="p-6 ">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">

                                <h2 className="text-sm font-bold mt-2">
                                    Passed
                                </h2>
                                <p>
                                    {data.passedAPIs}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-md bg-gray-950 shadow-md">
                    <div className="p-6 ">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">

                                <h2 className="text-sm font-bold mt-2">
                                    Failed
                                </h2>
                                <p>
                                    {data.failedAPIs}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <div className="overflow-x-auto">
          <table className="table-auto border-collapse border border-gray-700 w-full text-sm text-left text-gray-400">
            <thead className="bg-gray-800 text-xs uppercase text-gray-400">
              <tr>
                <th className="border border-gray-700 px-4 py-2">SNO</th>
                <th className="border border-gray-700 px-4 py-2">Endpoint</th>
                <th className="border border-gray-700 px-4 py-2">Desc</th>
                <th className="border border-gray-700 px-4 py-2">Status</th>
                <th className="border border-gray-700 px-4 py-2">Passed</th>
              </tr>
            </thead>
            <tbody>
              {data.results.map((result, index) => (
                <tr key={index} className="bg-gray-900 hover:bg-gray-800">
                  <td className="border border-gray-700 px-4 py-2">{result.id}</td>
                  <td className="border border-gray-700 px-4 py-2"><a target="_blank" href={"https://node.aryzap.com"+result.endpoint}>{result.endpoint }</a></td>
                  <td className="border border-gray-700 px-4 py-2">{result.description}</td>
                  <td className="border border-gray-700 px-4 py-2">{result.status}</td>
                  <td className="border border-gray-700 px-4 py-2">
                  {result.passed ? (
                    <CheckIcon className="w-5 h-5 mr-2" color="#31b100" />
                    ) : (
                    <XMarkIcon className="w-5 h-5 mr-2" color="red" />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default TestAllEndpoints;
